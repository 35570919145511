
export const initial = {
    count: 0,
    isLoading: false,
    tasks: [],
};

export default function createCard({ data, key, onClick, navigate }) {
    // function truncateString(text, min, max) {
    //     if (!text) {
    //         return;
    //     }
    //     if (text.length < max) {
    //         return text;
    //     } else {
    //         return text.substring(0, min) + " [...]";
    //     }
    // }
    return data.map(driver => {
        const tags = [];

        tags.push(
            ...[
                {
                    bgcolor: "#0079BF",
                    color: "white",
                    title: "ABC",
                    // onClick: () => {
                    //     navigate(`/gestao-de-viagens/detalhes/${driver.user.identifier}`);
                    // },
                },
                {
                    bgcolor: "#0079BF",
                    color: "white",
                    title: "ABC",
                    // onClick: () => {
                    //     history.push(`/clients/${driver.user.identifier}`);
                    // },
                },
            ]
        );

        return {
            id: driver.user.identifier,
            draggable: false,
            title: driver.user.name,
            // description: driver.user.cellphone,
            // label: "",
            // tags: tags,
            showDeleteButton: false,
            onClick: () => onClick(driver.id)
        };
    });
}
