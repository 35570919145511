import React, { useEffect, useState, useRef, useCallback } from "react";
import api from "../../../services/api";
import createCard from "../utils/cards";
import Trello from "../../../components/Trello";
import { navigate } from "gatsby";

const TrelloCard = ({ lanes }) => {
    const cardsRef = useRef({});
    const [cards, setCards] = useState({});

    const handleClick = useCallback(identifier => {
        navigate(`/gestao-de-motoristas/detalhes/${identifier}`);
    }, []);

    useEffect(() => {
        const _cards = cardsRef.current;
        const _lanes = lanes.current;
        Object.entries(_lanes).forEach(([key, value]) => {
            if (value.route) {
                api.get(value.route, {
                    headers: {
                        Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                    },
                }).then(response => {
                    const { data, meta } = response.data;
                    _cards[key] = {
                        count: meta.item_count,
                        loaded: true,
                        cards: createCard({ data, key, onClick: handleClick, navigate }),
                    };
                    setCards({ ..._cards });
                });
            } else {
                _cards[key] = {
                    count: 0,
                    loaded: true,
                    cards: [],
                };
            }
        });
    }, [handleClick, lanes]);

    return <Trello lanes={lanes.current} cards={cards} />;
};

export default TrelloCard;
